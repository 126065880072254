.noc_description {
  // font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #666666;
}

.m-32 {
  margin: 16px 0px;
}

.noc_info_content {
  background: #f2f2f2;
  border-bottom: 1px solid #f7f7f7;
  border-radius: 8px;
  padding: 8px;
  margin-top: 20px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
}

.noc_type {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.noc_type_error {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ff2222;
  height: 12px;
}
