.verification_pending_modal_container {
  border-radius: 15px !important;
  .pending_verification_doc_name {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #000000;
    max-width: 210px;
  }

  .pending_verification_badge {
    border-radius: 27px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding: 4px 8px;
  }

  .pending_verification_doc_status_verifying {
    background: #fcefe8;
    color: #fbaf3d;
  }

  .pending_verification_doc_status_verified {
    background: #e4f6e8;
    color: #52ce6e;
  }
}

.verification_pending_description {
  //   font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #666666;
}
