.drag_and_drop_container {
  #form-file-upload {
    height: 301.59px;
    text-align: center;
    position: relative;
  }

  #input-file-upload {
    display: none;
  }

  #label-file-upload {
    height: 301px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-width: 2px;
    border-radius: 8px;
    border-style: dashed;
    border-color: #0691fc;
    // border: 1px dashed #0691FC;
    background-color: #e8f3fc;
    margin-bottom: 20px;
  }

  #label-file-upload.drag-active {
    background-color: #ffffff;
  }

  .drag_drop_line {
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .upload-button {
    cursor: pointer;
    color: #0691fc;
    text-decoration: underline;
  }

  .upload-button:hover {
    text-decoration-line: underline;
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .drag_drop_text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  .supported_format {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #666666;
  }

  .drag_image {
    padding: 40px;
  }

  .uploading_container {
    font-size: 14px;
    line-height: 21px;
    color: #676767;

    .uploading_text {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #676767;
      margin-bottom: 8px;
      display: block;
    }

    .file_name_container {
      border: 0.5px solid #cccccc;
      border-radius: 4px;
      margin-top: 8px;
      padding: 8px 10px !important;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #000000;

      .file_cross {
        background: #cccccc;
        transform: matrix(1, 0, 0, -1, 0, 0);
        border-radius: 50%;
        padding: 2px;
      }
    }

    .file_progress {
      height: 3px !important;
    }
  }
}
