.navbar_container {
  position: fixed;
  top: 0;
  left: 255px;
  height: 84px;
  width: calc(100% - 255px);
  padding-right: 20px;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  z-index: 1;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.1);
  .navbar_component_name {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-transform: capitalize;
    color: #000000;

    border-left: 4px solid var(--Primary-2, #0691fc);
    display: flex;
    padding: 8px 20px;
    align-items: flex-start;
    gap: 10px;
  }

  .navbar_items_border {
    box-sizing: border-box;
    border: 1px solid #d0d0d0;
    border-radius: 50%;
    padding: 5px;
  }

  .navbar_user_detail_border {
    position: relative;
    box-sizing: border-box;
    border: 1px solid #d0d0d0;
    border-radius: 50px;
    padding: 3px 12px 3px 8px;

    .user_image {
      width: 36px;
      height: 36px;
      border: 1px solid #15d192;
      border-radius: 79px;
      margin: 0px 8px 0px 0px;
    }

    .user_name {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #4d4d4d;
    }

    .user_id {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: #7a7a7a;
    }

    .logout_menu {
      position: absolute;
      right: 8px;
      top: 100%;
      box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      background: #fff;
      z-index: 15 !important;
      padding: 8px;
      width: 170px;
      cursor: pointer;
      .menu_item {
        display: flex;
        width: 100%;
        padding: 8px;
        align-items: center;
        gap: 12px;

        .logout_icon {
          width: 16px;
          height: 16px;
        }
      }

      .menu_item:hover {
        border-radius: 8px;
        background: #e8f3fc;
      }
    }
  }
  .toggle-set{
    display: none;

  }
  .bima-image{
    display: none ;

  }
}

.navbar_rm_info_border {
  position: relative;
  box-sizing: border-box;
  border: 1px solid #d0d0d0;
  border-radius: 12px;
  padding: 6px 10px 6px 10px;
  width: auto;
  height: 58px;
  background: linear-gradient(89.57deg, rgba(191, 227, 255, 0.7), rgba(249, 214, 255, 0.7));

  .dedicated_rm {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    color: #666666;
    width: 102px;
    height: 15px
  }

  .rm_name {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    color: #A739A2;
    width: auto;
    height: 18px
  }

  .rm_code {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    color: #A739A2;
    width: 102px;
    height: 13px
  }
}

// tabbing css  glossary tabbing css    // 
.modal{
  --bs-modal-border-width:none !important; 
}
.model-body-grid{
  width:703px   !important ;
  top: 187px !important;
  background-color: white !important;
  border-radius: 9px !important;
  position: absolute !important;
  padding: 0px !important;
  border-radius: 10px !important;

}

.glossary-main-tab{
  margin-bottom: 0px ;
  margin: 1px 1px;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  width: 720px !important;
  border-radius: 15px !important;
}
.glossary-Tabs{
  width:220px !important;
  margin: 13px 0px !important;
}
.Tab-conent {
  display: flex ;
  background-color: white !important;
}
.tab-pane{
  width: 461px ;
  gap: 20px ;
  display: flex !important ;
  justify-content: center !important ;
  padding: 16px 19px 70px 19px  !important;

}
.glossary-tab-naving{
 width: 420px;
 background-color:  rgba(242, 242, 242, 1);
 border-radius: 12px 0px 0px 12px  !important;
 margin:0px  -1px ;
}
.glosary-tab-btn{
  padding: 10px 15px 10px 15px  !important ;
  border-radius: 0px 30px 30px 0px !important ;
  color: rgba(102, 102, 102, 1) !important; // Default color for non-active tabs
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;

}
.glosary-tab-btn.active {
  background-color: #0691FC ; // Active tab color
   // Background for active tab
  border-radius: 30px 0px 30px 0px;
  color: white !important;
}
.icon-tabbing {
  padding-right: 15px ;
}
.icon-tabbing  :hover{
  color: white !important;
}
.cross-icon{
  position: absolute;
  right: 15px;
  top: 13px;
}
.tab-content-set {
  font-size: 14px ;
  line-height: 21px;
  font-weight: 400;
  padding: 0px 5px;
  color: #181818 !important;
}
.nav{
  margin-bottom: 5px ;
}
.cross-icon:hover{
  color: black !important;

}

.grid-main-model{
  border-radius: 10px !important;
}

.modal-body {
  border-radius: 12px !important;
}
// ------ //
@media only screen and (min-width: 350px) and (max-width: 985px) {
  .navbar_container{
    left: 0px;
    position: fixed;
    top: 0px;
    width: 100%;
    padding: 0px 10px ;

  }
  .navbar_component_name{
    display: none !important;
  }
  .toggle-set{
    display: block !important;
  }
  .bima-image{
    display: block !important;
    

  }
  .navbar_items_border{
    display: none !important
    ;

  }

}