.input_group {
  position: relative;
}

.input_group .input {
  height: 59px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px !important;
  border-radius: 8px;
  border: 1px solid #cccccc;
}
// .input_group::after {
//   content: "*";
//   position: absolute;
//   top: -2px;
//   /* left: 146px; */
//   color: #f00;
// }

// .pan_name::after {
//   left: 198px !important;
// }

// .pan_number::after {
//   left: 155px !important;
// }

textarea:focus,
input:focus {
  outline: none;
}

textarea:disabled,
input:disabled {
  outline: none;
  background-color: #cccccc;
  border: none;
}

.verify_pan_btn {
  background: #52ce6e;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
  color: #ffffff;
  position: absolute;
  /*  */
  right: 4px;
  z-index: 2;
  border: none;
  top: -16px;
  height: 52px;
  width: 121px;
  cursor: pointer;
  padding: 2px;
}

.verified_pan_status {
  position: absolute;
  right: 4px;
  z-index: 2;
  border: none;
  top: -1px;
  width: 91px;
  background: #e4f6e8;
  color: #52ce6e;
  border-radius: 27px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding: 4px 8px;
}
