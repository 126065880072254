.profiling-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-family: poppins;
    font-size: 12px;
    .profiling-header {
      display: flex;
      position: relative;
      .profiling-x{
        position: absolute;
        right: 0;
        top: 0;
        padding: 4px;
        cursor: pointer;
        transform: translate(-50%, 50%);
        // gap: 10px;
        border-radius: 50px;
        background-color:  #FFFFFF99;
;
      }
      justify-content: space-between;
      .profiling-header-image {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    .profiling-small-text{
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #666;
    }
    .profiling-green-text{
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #34C759;
    }

    .profiling-red-text{
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #DF0000;
      ;
    }
  
    .profiling-body {
      padding: 0px 48px;
    
      .profiling-body-top {
        display: flex;
        justify-content: space-between;
        align-items: center;    
        flex-direction: column;
        gap:24px;
  
        .profiling-info {
            width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
        justify-content: space-between;
          .profile-image {
            position: relative;
            width: 100px;
            height: 50px;
            .profile-img {
              width: 100px;
              height: 100px;
              border-radius: 50%;
              object-fit: cover;
              border: 2px solid #ccc;
              position: absolute;
              bottom: 0;
              transform-origin: top;
            }
  
            .profile-id {
              position: absolute;
              bottom: -10px;
              background: #007bff;
              color: #fff;
              padding: 2px 6px;
              border-radius: 12px;
              font-size: 12px;
              left: 50%;
                transform: translateX(-50%);
            }
          }
  
          .pos-certificate-btn {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 0px 8px;
            border-radius: 8px;
            border: 1px 0px 0px 0px;
            // opacity: 0px;
            border: 1px solid #87CBFF;
            box-shadow: 0px 1px 4px 0px #00000024;
            background: #E8F3FC;
            color: #0691FC;
            margin-top: 20px;
            font-weight: 600;
          }
        }
  
        .profiling-summary {
          flex: 1;
          width: 100%;
          display: flex;
          flex-direction: row;
        justify-content: space-between;
          .profile-name {
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }
  
          .profile-joined {
            color: #666;
            font-size: 14px;
            margin-bottom: 8px;
          }
  
          .profile-progress {
            width: 164px;
            // height: 2px;
            font-size: 10px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            font-weight:400;
            color: #666;
            span{
              font-weight: 500;
              line-height: 15px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: black;
            }
            }
          
        }
      }
  
      .profiling-details {
        margin-top: 20px;
  
        .detail-section {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          gap: 24px;
          .bank-details{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
            .profiling-red{
            background: linear-gradient(92.46deg, #FFE5E5 -15.63%, #FFFFFF 81.27%);
            }
            .profiling-yellow{
              color: var(--Colors-Orange, #FF9500);
            }
            .profiling-bank-details-button{
              padding: 10px 24px 10px 24px;
              gap: 10px;
              border-radius: 8px;
              // opacity: 0px;
              background: #000000;
              color: #fff;
              text-align: center;
              cursor: pointer;
            }
            .bank-details-top-container{
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                p{
                  display: flex;
                align-items: center;
                }
            }
            .gst-info{
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }

          .profiling-card-container{

            .gst-button-container{
              display: flex;
              align-items: center;
              justify-content: space-between;
              div{
                width: 50%;
                text-align: center;
                cursor: pointer;
              }
              .gst-underline{
                font-weight: 500;
                text-decoration-line: underline;
                text-decoration-style: solid;
              }
            }
            h3{
              font-size: 12px;
              font-weight: 600;
              line-height: 18px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink  : none;
              color: #181818;
  
            }
            p{
              display: flex;
              align-items: center;
            }
            min-height: 144px;
            // display: flex;
            width: 50%;
            padding: 16px 20px 16px 20px;
            gap: 8;
            border: 1px solid #F2F2F2;
            border-radius: 16px;
            background-color: #fff;
          }
          .other-info{
            min-height: 144px;
            width: 33%;
            padding: 16px 20px 16px 20px;
            gap: 8;
            border: 1px solid #F2F2F2;
            border-radius: 16px;
            background-color: #fff;

            h3 {
              font-size: 12px;
              font-weight: 600;
              line-height: 18px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: #181818;
  
            }
            h4{
              font-size: 12px;
              font-weight: 600;
              line-height: 18px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: var(--Colors-Green, #34C759);
  
            }
            p{
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: #666;
            }
          }

          .education-doc{
            display: flex;
            justify-content: space-between;
          }
          
          p {
            margin: 4px 0;
  
            &.status {
              font-weight: bold;
  
              &.not-updated {
                color: #dc3545;
              }
            }
          }

  
  
          button {
            display: block;
            margin-top: 8px;
            padding: 6px 12px;
            border-radius: 4px;
            border: none;
            cursor: pointer;
  
            &.update-gst-btn {
              background: #007bff;
              color: #fff;
  
              &:hover {
                background: #0056b3;
              }
            }
  
            &.dont-have-gst-btn {
              background: #e0e0e0;
              color: #333;
  
              &:hover {
                background: #ccc;
              }
            }
          }
        }
      }
    }
  }
  