.upload_btn_container {
  .upload_btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    height: 34px;
    background: #e6f4ff;
    border-radius: 8px;
    border: none;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    text-transform: capitalize;
    color: #0691fc;
  }
}
