.policy-offer-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .favorite-card-header {
    display: flex;
    background: #e8f3fc;
    padding: 6px 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: fit-content;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
  }
  .favorite-card {
    // border: 1px solid #ddd;
    padding: 10px 32px;
    min-height: 120px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    width: 100%;
    .deal-type {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.41px;
      text-align: left;
      padding: 4px 8px;
      position: absolute;
      top: 0;
      left: -14px;
      z-index: 1;
      background-color: #0691fc;
      color: #fff;
      border-top-left-radius: 6px;
      border-bottom-right-radius: 6px;
      text-transform: capitalize;
      &:before {
        // triangle shape to shape box to left as 3d
        content: "";
        position: absolute;
        top: 24px;
        left: 0;
        width: 0;
        height: 0;
        border-bottom: 10px solid transparent;
        border-right: 14px solid #003c69;
        z-index: 0;
      }
    }

    &.market_deal {
      background: linear-gradient(
        90deg,
        #e0eef9 0%,
        rgba(226, 242, 255, 0) 100%
      );

      .deal-type {
        background-color: #0691fc;
        &:before {
          border-right: 14px solid #003c69;
        }
      }
    }

    &.decline_deal {
      background: linear-gradient(
        90deg,
        #ffe4e4 0%,
        rgba(255, 226, 226, 0) 100%
      );

      .deal-type {
        background-color: #df0000;
        &:before {
          border-right: 14px solid #640000;
        }
      }
    }

    &.special_deal {
      background: linear-gradient(
        90deg,
        #f9f6e0 0%,
        rgba(255, 252, 226, 0) 100%
      );

      .deal-type {
        background-color: #dfa100;
        &:before {
          border-right: 14px solid #533c00;
        }
      }
    }

    .item {
      width: 25%;
      display: flex;
      justify-content: flex-start;
    }

    .status {
      font-weight: bold;
      padding: 5px 10px;
      border-radius: 5px;
    }

    .insurer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      img{
        object-fit: contain;
        width: auto;
        height: 50px;
        max-width: 180px;
      }

      .logo_name {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #000;
      }

      .insurer-id {
        color: #777;
        font-size: 12px;
        line-height: 15px;
        margin-left: 15px;
      }
    }

    .business-type {
      display: flex;
      justify-content: flex-start;
      font-size: 14px;
      line-height: 21px;
      color: #000;
    }

    .parameters {
      line-height: 21px;
      font-size: 14px;
      color: #000;
      gap: 4px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .deal-percentage {
      justify-content: flex-center;
      flex-direction: column;
      color: #23963d;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
    }

    .favorite {
      font-size: 24px;
      position: absolute;
      right: 10px;
      top: 10px;
      .favorite-icon {
        transition: all 0.3s ease;
        cursor: pointer;
        &.active {
          color: #DF0000;
          fill: #DF0000;
          transition: all 0.3s;
        }
      }
    }
  }
}
