.start_training_container {
  padding: 16px 24px;
  gap: 16px;
  border-radius: 16px;
  background: var(--white, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

  .verification_complete_text {
    color: var(--black, #000);
    font-size: 14px;
    font-family: Poppins;
    font-weight: 500;
  }
}

.read_theory_keys {
  color: #666666;
  text-align: center;
  font-size: 12px;
  font-family: Poppins;
  text-transform: capitalize;
  margin: 40px 0px;
  list-style-type: disc;

  .list-item::marker {
    color: #d9d9d9;
    font-size: 1rem;
  }

  li {
    padding-left: 0.3em;
  }
}

.complete_theory_statement {
  display: flex;
  padding: 8px 24px !important;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  border-bottom: 1px solid #f7f7f7;
  background: #ffe6b5;
}

.footer_line {
  margin-top: 32px;
  margin-bottom: 24px;
}

.training_rule_back_btn {
  display: flex;
  padding: 16px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border: none;
  border-radius: 8px;
  background: #e6e6e6;
}

.trainging_rule_back_name {
  text-align: right;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 600;
  text-transform: capitalize;
  color: black !important;
}

.training_rule_regulation_list {
  margin-top: 32px;
  list-style: decimal;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  text-transform: capitalize;
  color: #666666;
}

.training_question_heading {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  text-transform: capitalize;
  margin: 32px 0px;
}

.training_question_options {
  display: flex;
  width: 300px;
  padding: 24px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f2f2f2;
  cursor: pointer;

  color: #000;
  text-align: center;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 500;
  text-transform: capitalize;
}

.highlighted_answer {
  border: 1px solid #0691fc;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.training_progress_bar_container {
  .progress_badge {
    display: flex;
    padding: 4px 16px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: #e8f3fc;
    min-width: 85px;
    max-width: 95px;

    color: #0691fc;
    text-align: right;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 500;
  }
}

.questions_submit_modal {
  .questions_attempt {
    text-align: right;
    font-size: 12px;
    font-family: Poppins;
  }
  .total_score {
    color: #23963d;
    text-align: right;
    font-size: 24px;
    font-family: Poppins;
    font-weight: 600;
  }

  .fail_score {
    color: #df0000;
    text-align: right;
    font-size: 24px;
    font-family: Poppins;
    font-weight: 600;
  }
}

.training_time_container {
  padding: 16px 24px;
  gap: 16px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

  .view_details_btn {
    color: #0691fc;
    text-align: right;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .training_heading {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .timer_badge {
    width: 85px;
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    background: #e8f3fc;
    color: #0691fc;
    text-align: right;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  // .training_heading {
  //   // font-family: Poppins;
  //   font-size: 14px;
  //   font-style: normal;
  //   font-weight: 400;
  //   line-height: normal;

  //   .remaining_badge {
  //     color: black;
  //     background: #ffbf1a;
  //     padding: 5px;
  //     border-radius: 5px;
  //   }
  // }

  // .timer {
  //   text-align: right;
  //   // font-family: Poppins;
  //   font-size: 32px;
  //   font-style: normal;
  //   font-weight: 500;
  //   line-height: normal;
  // }

  // .timer_btn {
  //   border-radius: 4px;
  //   background: #0c385a;
  //   border: 1px solid #0691fc;
  //   display: flex;
  //   padding: 4px;
  //   align-items: flex-start;
  //   gap: 10px;

  //   .timer_btn_name {
  //     color: #0691fc;
  //     text-align: right;
  //     font-family: Poppins;
  //     font-size: 12px;
  //     font-style: normal;
  //     font-weight: 500;
  //     line-height: normal;
  //     text-transform: capitalize;
  //   }
  // }
}
