.verify_aadhar_btn {
  margin-top: 40px;
}
@media only screen and (min-width: 350px) and (max-width: 985px){
 
  .modal-title {
    font-size: 20px !important;
    line-height: 30px !important;
    font-weight: 600;
  }
  .drag_and_drop_container #label-file-upload{
    height: 273px !important;
    margin-bottom: 0px !important;
  }
  .drag_and_drop_container .drag_image {
    padding: 15px;
}
.verify_aadhar_btn {
  margin-top: 18px;
}
.verified_text{
  font-size: 20px; 
  line-height: 28px ;
}

}