
.tab-set {
    font-size: 14px !important;
    font-weight: 600;
    color: #00223c;
  }
  .nav-tabs .nav-link.active{
    border-bottom: 3px solid #0691FC !important;
 }

 
 .Nav-items {
    border-right: 0.5px solid var(--Gre-85, #d9d9d9) !important;

 }
 .Nav-items:active{
    border-radius: none !important;
 }
 .Nav-items:last-child {
    border-right: none !important; 
  }
  .set-nav-link{
    border-radius: 12px !important;
  }
  
//   .set-nav-link {
//     border-radius: 12px !important;
//     overflow: hidden; /* Ensures content inside respects the rounded corners */
//   }
  
  .nav-tabs {
    border-radius: 12px !important; /* Ensures the parent tab navigation has rounded corners */
    overflow: hidden; /* Prevents child elements from overflowing */
  }
  .nav-tabs:active{
    border-radius: none !important; /* Ensures the parent tab navigation has rounded corners */
  }

  