.error-boundary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f8f8f8;
    color: #ff0000;
    font-size: 18px;
    text-align: center;
}

.error-boundary h1 {
    font-size: 24px;
    margin-bottom: 16px;
}

.error-boundary p {
    margin-bottom: 8px;
}

.error-boundary button {
    padding: 8px 16px;
    background-color: #ff0000;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.error-boundary button:hover {
    background-color: #cc0000;
}