.blur_overlay {
    // overlay without blur
    background: rgba(0, 0, 0, 0.3);
    z-index: 3;
    &.show {
        backdrop-filter: blur(2px);
        // background: rgba(255, 255, 255, 0.5);
        background: #17282F30;
    }
}

