.grid-component-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: -24px !important;

  .modal_header {
    background: #00223c;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 12px;
    border-radius: 0px 0px 24px 24px;
    color: #fff;
    width: fit-content;
    // border-bottom: 1px solid #e6e6e6;

    .header_items {
      display: flex;
      width: 90px;
      padding: 8px 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #fff;

      .veichle_name {
        color: #ccc;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .active_veichle_name {
        color: #fff;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .active_header_item {
      border-radius: 12px;
      background: #0090FF;
    }
  }
  .grid-options {
    display: flex;
    flex-direction: row;
    width: fit-content !important;
    background-color: white;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 32px;
    gap: 30px;
    border-radius: 24px;
    .grid-options-container {
      min-width: calc(25% - 30px);
      max-width: calc(25% - 30px);
      // max-width: 25%;
    }
    .flatpickr-input {
      font-size: 14px !important;
    }
    .grid-options-container:last-child {
      margin-left: auto;
      margin-right: 26px;
    }
    .secondary_button {
      width: 100%;
      height: 50px;
      // background-color: #0691FC;
      color: white;
      // padding: 4px;
    }
  }
}
