.car-info-card {
    display: flex;
    align-items: center;
    background-color: #08243a;
    color: #ffffff;
    padding: 10px 0px;
    border-radius: 10px;
    margin-bottom: 20px;
    .icon {
      background-color: #FFFFFF1A;  
      font-size: 24px;
      padding: 0px 16px 0px 16px;
      border-radius: 0px 16px 16px 0px;
    }
  
    .info {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 100%;
      // align-items: left;
      .policy_header_row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        // align-items: center;
        // gap: 5px;
        padding-left: 10px;
        border-left: 1px solid #FFFFFF1A;
        &:last-child {
          border-right: none;
        }
        .label{
          color: rgba(255, 255, 255, 0.78) !important;
        }
        .label,.value {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
        }
  
        .value {
          display: flex;
          gap: 5px;
          align-items: center;

  
          &.insurers,
          &.type {
            display: flex;
            gap: 5px;
  
            .insurer,
            .type {
              background: #F2F2F20D;
              // opacity: ;
              padding: 5px 10px;
              border-radius: 15px;
            }
          }
        }
      }
    }
  
    .edit {
      button {
        background: #FFFFFF1A;
        color: #ffffff;
        border: none;
        cursor: pointer;
        font-size: 14px;
        padding: 8px 11px 8px 11px;
        gap: 4px;
        border-radius: 8px 0px 0px 8px;


  
        &:hover {
          background-color: #006999;
        }
      }
    }
  }
  