.aadhar_container {
  .aadhar_info_heading {
    display: flex;
    align-items: center;
    gap: 2px;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #f8b83c;
  }

  .allow_authorise {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #666666;
  }

  .otp_input_container {
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .otp_input_style {
    width: 50px !important;
    height: 59px;
    border: 1px solid #cccccc;
    background: white;
    color: #666666;    ;
    border-radius: 8px;
    display: flex;
    padding: 0 !important;
  }
}

.aadhar_information_container {
  padding: 16px;
  min-height: 192px;
  background-color: #f2f2f2 !important;
  border-radius: 16px !important;
  border: none !important;

  .user_image {
    width: 85px;
    height: 88px;
  }

  .detail_heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #7a7a7a;
  }

  .detail_answer {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    color: #141414;
  }
}

.same_aadhar_content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #181818;
}

.state_error {
}

.pincode_city {
  padding: 4px 16px;
  gap: 10px;
  border-radius: 27px;
  background: #e8f3fc;
  width: fit-content;

  text-align: center;
  right: 0;
  position: absolute;
  z-index: 99;
  margin-top: -55px;
  margin-right: 25px;

  color: #0691fc;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media screen and (max-width: 600px){
  .otp_input_style{
    font-size: 16px !important;
  }
}

