.modal_container {
  .modal-content {
    
    border: none;
    border-radius: 24px;
    padding: 10px 40px;
    min-height: 316px;
    
    .modal_header {
      border: none;
      font-family: "Poppins";
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      text-transform: capitalize;
      color: #000000;
      text-align: center;

      .btn-close {
        position: absolute;
        right: 25px;
        top: 20px;
        background-color: #e6e6e6;
        border-radius: 50px;
        font-size: 14px !important;
      }
    }
  }
  &.no_modal_padding {
    .modal-content{
      padding: 0 ;
      overflow: hidden;
    }
    padding: 0;
  }

  .extra_toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    border-radius: 50px;
    background: #e6e6e6;
    border: none;
    color: #323232;
  }
}

.modal_backdrop_fade {
  background: rgba(0, 0, 0, 0.3) !important;
  backdrop-filter: blur(15px) !important;
}

.header_bottom {
  color: #666666;
  height: 10px;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .modal_container .modal-content{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding:24px 15px;
    gap: 24px;

    .modal_header{
      padding: 0;
      font-family: Poppins;
      font-size: 20px !important;
      font-weight: 600;
      line-height: 30px;
      text-align: left;

    }
    .modal-body{
      padding: 0;
    }
  }
}
@media only screen and (min-width: 601px) {
  .modal_container .modal-content{
    padding: 24px 10px;
  }
}
