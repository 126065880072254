.pending_status {
    display: flex;
    padding: 4px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 27px;
    background: #ff3e78;
    width: max-content;
    color: #fff;
    font-size: 12px;
  }