.modal_section_container {
  background: #001c31 !important;
  border-radius: 16px !important;
  flex-direction: initial !important;
  padding: 24px !important;
  color: #ffffff;
  height: 117px !important;

  .partner_journey_upload_btn {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #0691fc;
    border: none;
    position: absolute;
    right: 24px;
    top: 10px;
    color: #fff;
    text-align: right;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .steps_position {
    position: absolute;
    top: 66px;
    max-width: calc(100% - 100px);
  }

  .modal_info {
    .user_details {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-top: 0px !important;
    }

    .user_name_headeing {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .steps_content:not(:first-child) {
      margin-left: 34px;
    }

    .complete_profile_heading {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .step_name {
      width: max-content;
      color: #e6e6e6;
      text-align: center;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }

    .each_modal_item {
      margin-left: 24px;
      display: block;
    }
  }
}
br {
  display: none; /* This might be hiding <br /> on mobile screens */
}


@media only screen and (min-width: 350px) and (max-width: 985px) {
  .modal_section_container{
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    padding: 20px 10px !important;
    height: 210px !important;
     gap: 10px  !important; 

  }
  .modal_info{
    .user_name_headeing{
      font-size:  14px !important;
      line-height: 18px !important ;
    }
    .complete_profile_heading{
      font-size: 10px !important;
      line-height: 18px !important;
  
    }
  }
  .top-model-section{
    margin-bottom: 0px !important;

  }
  br {
    display: block !important; /* This might be hiding <br /> on mobile screens */
  }
  .each_modal_item{
    display: none !important;
    margin: 0px !important;

  }
  .modal_section_container .partner_journey_upload_btn{
    top: 143px !important;
    width: 92% !important;
    left: 13px !important;
    right: 0px !important;
  }
  .modal_section_container .steps_position{
    width: fit-content !important;
    padding: 10px 0px  !important;
    top:74px !important;
    left: 10px !important;

  
  }
  .modal_section_container .modal_info .step_name {
    font-size: 9px !important;
  }
  
  .modal_section_container .modal_info .steps_content:not(:first-child) {
    margin-left: 9px !important;
    align-items: center !important;

}
.user_details{
  margin-left: 2px !important;
  margin-bottom:  6.1875rem !important;
 
}

.modal_section_container .modal_info .user_details{
  line-height: 16px !important;
  margin-top: 3px !important;
}
.steps_content{
  flex: 0 0 auto !important;
  width: 25% !important;


}
.inner-model-items{
  gap: 7px !important;

}





}