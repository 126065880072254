.custom_accordion_container {
  .single_accordian {
    display: flex;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #e6e6e6;

    .header_content {
      padding: 12px;
      border-bottom: 1px solid #e6e6e6;
      .active_header {
        color: #181818;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }

  .accordion_content {
    .accordion_title {
      color: #808080;
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .accordion_value {
      color: #181818;
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
