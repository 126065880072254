/* Globals variables */
/* text color */
:root {
  --gray-dark: #666666;
  --gray-95: #f2f2f2;
  --blue-lighter: #d2e8f9;
  --blue-dark: #0691fc;
  --white: #ffffff;
  --linear-gradient-blue-left: linear-gradient(
    271.24deg,
    #01dcfa -32.38%,
    #0590fb 101.16%
  );
  --linear-gradient-blue-right: linear-gradient(
    89.24deg,
    #01dcfa -32.38%,
    #0590fb 101.16%
  );
}

.App {
  min-height: calc(100vh - 124px);
}

.error_class {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12px;
  color: #dc3545;
  height: 0.9em;
}

.custom_badge {
  padding: 8px 16px;
  width: fit-content;
  height: 34px;
  background: #f2f2f2;
  border-radius: 38px;
  margin-right: 8px;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.active_badge {
  border-radius: 38px;
  border: 1px solid #0691fc;
  background: #e8f3fc;
  color: #0691fc;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.custom_badge .custom_badge_check {
  background-color: #0691fc;
  border-radius: 50%;
  padding: 1px;
  margin-right: 5px;
}

.verified_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
  color: #000000;
}

.verified_description {
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #181818;
}

.upload_checque_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  height: 34px;
  background: #e6f4ff;
  border-radius: 8px;
  border: none;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  text-transform: capitalize;
  color: #0691fc;
}

.modal-open {
  padding-right: 0 !important;
}

.modal-backdrop.show {
  opacity: 0 !important;
}
.modal.fade.show {
  background: rgba(0, 0, 0, 0.3) !important;
}

.cursor_pointer {
  cursor: pointer;
}

/* .select_dropdown {
  height: 50px;
  padding: 0px;
  border-radius: 8px;
  border: 1px solid #ccc;
  color: #b3b3b3;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
} */

.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
}

.submit_btn {
  padding: 12px 48px !important;
  display: flex;
  justify-content: center;
}

.cancel_btn {
  display: flex;
  padding: 12px 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background: #fff;
}

.cancel_btn_name {
  color: #ccc;
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.flat_picker_date {
  border-radius: 8px;
  border: 1px solid #ccc;
  display: flex;
  height: 50px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  color: #b3b3b3;
  background: #fff;
  font-family: Poppins !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding-left: 10px;
  width: 100%;
}

.flat_picker_date:focus {
  outline: none;
  border: 1px solid #0691fc;
}

.flat_picker_date:hover {
  border: 1px solid #0691fc;
}

/* margins */
.mb_25 {
  margin-bottom: 25px;
}

.mb_12 {
  margin-bottom: 12px;
}

.mb_16 {
  margin-bottom: 16px;
}

.mb_0 {
  margin-bottom: 0px;
}

.mt_16 {
  margin-top: 16px !important;
}

.mt_12 {
  margin-top: 12px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_24 {
  margin-top: 24px;
}

.ml_24 {
  margin-left: 14px !important;
}

.margin__30 {
  margin-left: -35px;
}

/* Padding */
.py_0 {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.px_0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.p_24 {
  padding: 24px;
}

.p_40 {
  padding: 40px;
}

.py_24 {
  padding: 24px 0px;
}

.pb_24 {
  padding-bottom: 24px;
}

.pb_16 {
  padding-bottom: 16px;
}

.py_8 {
  padding: 8px 0px;
}

.p_12 {
  padding: 12px;
}

.p_16 {
  padding: 16px;
}

.py_16 {
  padding: 16px 0px;
}
.px_40 {
  padding-left: 40px;
  padding-right: 40px;
}

.gap_100 {
  gap: 305px;
}
.gap_12 {
  gap: 12px;
}

.gap_8 {
  gap: 8px;
}
.align_center {
  align-items: center;
}

.justify_space_between {
  justify-content: space-between;
}

/* Background colors */
.background_dark_blue {
  background: #00223c !important;
}

.background_light_orange {
  background: #fcefe8 !important;
}

/* webkit scrollbar with 5 width light grey color */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

.w-fit-content {
  width: fit-content;
}

/* date-picker scss  */
.date-picker {
  border: 1px solid #ccc;
  padding-left: 16px;
  color: #828282 !important;
  font-weight: 400 !important;
  outline: none !important;
  text-align: start !important;
  cursor: pointer;
  min-height: 40px !important;
  min-width: 180px !important;
  background-color: white;
  border-radius: 8px !important;
  font-size: 12px;

  &:focus {
    outline: none;
    border: 1px solid #0691fc !important;
  }

  &:hover {
    border: 1px solid #0691fc;
  }

  &-error {
    border: 1px solid var(--color-red);
  }

  &::placeholder {
    text-align: start !important;
    color: #828282 !important;
    font-weight: 400 !important;
    margin-left: 2% !important;
    font-size: 14px;
  }
}

.flatpickr-input[readonly] {
  font-size: 10px !important ;
}

.flatpickr-months .flatpickr-month {
  background-color: #42a5f5 !important;
}
