.gst_modal_container {
  .gst_authorised {
    color: #666;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .gst_helping_text {
    color: #f8b83c;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .have_gst_number {
    color: #181818;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .gst_details {
    border-radius: 16px;
    background: #f2f2f2;
    padding: 16px;

    .detail_content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .gst_lable {
        color: #7a7a7a;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .gst_value {
        color: #141414;
        text-align: right;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
