.gtd_container_wrapper {
    z-index: 9999 !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    max-height: 100vh;
    overflow-y: hidden;
}

.gtd_container{
    background-color: #00223C;
    color: white;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
    height: 100%;
    overflow-y: auto;
}
.lottie_wrapper{
    background: #23963D;
    padding: 24px 0px 24px 0px;
    gap: 8px;
    min-height: fit-content;
}
.gtd_p{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    /* line-height: 32px; */
    text-align: center;
    color: #E6E6E6;

}
.gtd_second{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 16px 0px 16px;
    gap: 24px;
}