.layout_wrapper {
  .layout_sidebar {
    z-index: 2;
    position: fixed;
    display: flex;
    height: 100vh;

  }

  .layout_body {
    margin-left: 255px;
    // padding-left: 15px;
    // padding-right: 20px;
    background: #f7f7f7;
    z-index: 1;
    position: relative;
  }

  .layout_main {
    margin-top: 84px;
    padding: 24px;
    padding-bottom: 0;
    background: #f7f7f7;
    z-index: 0;
    position: relative;
    min-height: calc(100vh - 84px);
  }
}
@media only screen and (min-width: 350px) and (max-width: 985px) {

  .layout_wrapper {
    .layout_sidebar{
      display: none !important;
    }
    .layout_body{
      margin: 0px !important;

    }
    .layout_main{
      padding: 0px !important;

    }
   
    }

}
