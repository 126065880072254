.quote_requeste_doc_upload_container {

  .fill_details_pin_code {
    padding: 4px 16px;
    border-radius: 27px;
    background: #e8f3fc;
    width: fit-content;
    text-align: center;
    right: 20px;
    position: absolute;
    z-index: 99;
    margin-top: -55px;
    color: #0691fc;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .doc_card {
    display: flex;
    padding: 8px 16px;
    gap: 16px;
    // justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    background: #fafafa;
    margin: 16px 0px;

    .doc_name_content {
      display: flex;
      // width: 400px;
      flex: 1 1 33%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .doc_name {
        color: #666;
        font-size: 14px;
      }
    }

    .doc_status_content {
      display: flex;
      // width: 550px;
      flex: 1 1 33%;
      align-items: center;
      justify-content: flex-start;
      // break-word: break-all;
      .doc_status {
        display: flex;
        padding: 4px 16px;
        align-items: center;
        gap: 10px;
        border-radius: 27px;
        background: #ff3e78;
        width: max-content;
        color: #fff;
        font-size: 12px;
      }
    }

    .doc_btn_content {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1 1 33%;
      // .upload_btn_container {
      //   .upload_btn {
      //     display: flex;
      //     flex-direction: row;
      //     align-items: center;
      //     padding: 8px 16px;
      //     gap: 8px;
      //     height: 34px;
      //     background: #e6f4ff;
      //     border-radius: 8px;
      //     border: none;

      //     font-family: "Poppins";
      //     font-style: normal;
      //     font-weight: 600;
      //     font-size: 12px;
      //     line-height: 18px;
      //     text-align: right;
      //     text-transform: capitalize;
      //     color: #0691fc;
      //   }
      // }
    }
  }

  .doc_submit {
    padding: 12px 48px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }
}
.quote_modal_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.quote_request_shared_quote_container {
  .quote_request_shared_quote_list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .quote_request_shared_all_quote_action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    // padding: 16px 0px;
    .view_all_quotes {
      color: #0691fc;
      font-size: 14px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
    .decline_text {
      color: #df0000;
      font-size: 14px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  .quote_card_container {
    border-radius: 16px;
    // min-height: 84px;
    box-shadow: 0px 2px 4px 2px #0000001a;
    background: #fff;
    // overflow: hidden;
  }

  .quote_addons_list {
    padding: 2px 16px;
    font-size: 12px;
    background-color: #E8F3FC;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .quote_card {
    // min-height: 80px;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
    border-radius: 16px;

    &.quote_accepted_card {
      background: #e2f1e6;
    }

    &.is_change_open {
      background: #e8f3fc;
    }

    &.quote_change_requested_card {
      background: #fcefe8;
    }
    

    .quote_card_main {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .quote_status_btns {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }

    .view_quote_btn {
      border-radius: 8px;
      height: 46px;
      width: max-content;
      border: none;
      color: #0691fc;
      text-align: center;
      padding: 12px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 12px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;

      &:hover {
        background: #e8f3fc;
      }
    }

    .quote_not_found_btn {
      // border-radius: 8px;
      cursor: unset;
      background: transparent;
      height: 46px;
      width: max-content;
      border: none;
      color: #666;
      text-align: center;
      padding: 12px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 12px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }

    .quote_change_requested_btn {
      background: #ffa800;
      color: #fff;
      border-radius: 8px;
      width: max-content;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      padding: 2px 12px;
    }

    .option_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 8px;
      font-size: 12px;
      border: none;
      background: transparent;
      cursor: pointer;
      &:hover {
        color: #666;
        background: #f7f7f7;
        border-radius: 8px;
        padding: 12px 8px;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    .accept_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      color: #23963d;
      padding: 12px 8px;
      &:hover {
        color: #23963d;
        background: #e2f1e6;
        border-radius: 8px;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    .changes_btn {
      color: #808080;
      padding: 12px 8px;
      &:hover {
        color: #808080;
        background: #f7f7f7;
        border-radius: 8px;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    .no_actions {
      width: 0;
    }

    .quote_form {
      .heading {
        color: #000;
        font-size: 14px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .cancel_btn {
        display: flex;
        padding: 12px 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: 1px solid #b3b3b3;
        background: #fff;

        .cancel_btn_name {
          color: #b3b3b3;
          text-align: right;
          font-size: 16px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
        }
      }
    }
  }
  .quote_card_badge{
    display: flex;
    padding: 2px 12px;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #09ce1d;
    color: #fff;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    &.quote_accepted_badge{
      background: #09ce1d;
    }
    &.quote_rejected_badge{
      background: #df0000;
    }
  }

  .quote_card_for_modal {
    background: #e6e6e6;
    // .logo_image {
    // }
    .modal_actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
    .modal_action_button,
    .quote_status_btn .modal_action_button a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .no_actions {
      width: unset;
    }
  }
  .quote_decline_border_radius{
    border-radius: 16px;
  }

  .quote_declined_card {
    border-radius: 16px;
    background: #f9e5e5;
    .decline_view_quote_btn {
      color: var(--grey-40, #666);
      border-radius: 8px;
      background: #e8f3fc;
      height: 46px;
      width: max-content;
      border: none;
      text-align: center;
      padding: 12px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 12px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }

    .decline_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #df0000;
      color: #fff;
      width: 120px;
    }
  }

  .quote_unavailable_card {
    border-radius: 16px;
    background: #e6e6e6;
    .logo_image {
      // grey scale the image
      filter: grayscale(100%);
    }

  }

  .quote_form_container {
    .input {
      background: #fff;
    }
  }

  .logo_name{
    font-size: 14px;
    font-weight: 500;
  }

  .quote_card_border_radius_due_to_addons {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}
