.button {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  padding: 16px 24px;
  gap: 10px;
  /* min-width: 156px; */
  width: max-content;
  height: 53px;
  background: #0691fc;
  border-radius: 8px;
  border: none;
}

.secondary_button {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  gap: 10px;
  width: max-content;
  height: auto;
  background: #0691FC;
  border-radius: 8px;
  border: 1px solid #0691fc;
  color: white;
}
.secondary_button.outlined{
  background-color: white;
  color: #0691fc;
}
.secondary_button.outlined:hover{
  color: white;
  background-color: #0691fc;
}

.secondary_button .btn_name {
  color: white;
  font-size: 14px;
}
.secondary_button.outlined .btn_name  {
  color: #0691fC;
  font-size: 14px;
}

.secondary_button:hover {
  background: #0691fc;
  color: #fff;
}

.secondary_button:hover .btn_name {
  color: #fff;
}

.decline_btn {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  padding: 12px 48px;
  gap: 10px;
  width: max-content;
  height: 53px;
  background: #ff0000;
  border-radius: 8px;
  border: none;
}

.cancel_btn {
  display: flex;
  padding: 12px 48px;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #b3b3b3;
  background: #fff;
}

.cancel_btn .cancel_btn_name {
  color: #b3b3b3;
  text-align: right;
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.cancel_btn:hover {
  border: 1px solid #ff0000;
}

.cancel_btn:hover .cancel_btn_name {
  color: #ff0000;
}

button:disabled,
button[disabled] {
  display: flex;
  /* flex-direction: column;s */
  align-items: flex-start;
  padding: 16px 24px;
  gap: 10px;
  width: max-content;
  height: 53px;
  border-radius: 8px;
  border: none;
  background: #b3b3b3;
  cursor: not-allowed;
}

.otp_button {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: flex-start;
  padding: 16px 32px;
  gap: 10px;
  width: 131px;
  height: 59px;
  background: #0691fc;
  border-radius: 0px 8px 8px 0px;
  border: none;
  width: max-content;
}

.btn_name {
  color: #fff;
  text-align: right;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  width: max-content;
}

.modal_btn {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 10px;
  width: 100%;
  height: 59px;
  background: #0691fc;
  border-radius: 8px;
  border: none;
}

@media only screen and (max-width: 600px) {
  .otp_button {
    width: 122px;
    height: 50px;
  }

  .btn_name {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
}